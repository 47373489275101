export const month = ["Gennaio","Febbraio","Marzo","Aprile","Maggio","Giugno","Luglio","Agosto","Settembre","Ottobre","Novembre","Dicembre",'Gennaio'];
export  const mesiWithZero = ['01','02','03','04','05','06','07','08','09','10','11','12'];

export const currentYear = (new Date()).getFullYear().toString();

export  const mesi = [
    {1:'Gennaio'},{2:'Febbraio'},{3:'Marzo'},{4:'Aprile'},{5:'Maggio'},{6:'Giugno'},
    {7:'Luglio'},{8:'Agosto'},{9:'Settembre'},{10:'Ottobre'},{11:'Novembre'},{12:'Dicembre'}];

export  const mesiFix = [
    {1:'Gennaio'},{2:'Febbraio'},{3:'Marzo'},{4:'Aprile'},{5:'Maggio'},{6:'Giugno'},
    {7:'Luglio'},{8:'Agosto'},{9:'Settembre'},{10:'Ottobre'}];

export  const mesiGrid = ["Dicembre", "Gennaio","Febbraio","Marzo","Aprile","Maggio","Giugno","Luglio","Agosto","Settembre","Ottobre","Novembre","Dicembre"];

export const tipoNotifica = [
    {"Digitali": 1},
    {"Analogico AR Nazionali": 2}, 
    {"Analogico AR Internazionali": 3},
    {"Analogico RS Nazionali": 4}, 
    {"Analogico RS Internazionali": 5}, 
    {"Analogico 890":6}];